import React from 'react'

import { Helmet } from 'react-helmet'

import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Biencos</title>
        <meta property="og:title" content="Biencos" />
      </Helmet>
      <div id="home" className="home-section-separator"></div>
      <div data-role="Header" className="home-navbar-container">
        <div className="home-navbar">
          <img alt="image" src="/logo_dark.svg" className="home-image" />
          <div className="home-links-container">
            <a href="#home" className="home-link Navbar-Link">
              Home
            </a>
            <a href="#services" className="home-link01 Navbar-Link">
              Services
            </a>
            <a href="#experience" className="home-link02 Navbar-Link">
              Experience
            </a>
            <a href="#contact" className="home-link03 Navbar-Link">
              Contact
            </a>
          </div>
        </div>
      </div>
      <div className="home-section-separator1"></div>
      <div className="home-home">
        <div className="home-hero">
          <div className="home-hero-text-container">
            <h1 className="home-heading Section-Heading">
              <span>Build something</span>
              <br></br>
              <span>great today</span>
            </h1>
            <span className="home-text03 Section-Text">
              <span>
                As a software engineer,
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>I help companies</span>
              <br></br>
              <span>
                deliver the right message to their customers.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
            <div className="home-cta-btn-container">
              <a href="#contact" className="home-cta-btn Anchor button">
                <span className="home-text08">Contact me</span>
              </a>
            </div>
          </div>
          <img alt="image" src="/phone-1400w.png" className="home-image2" />
        </div>
      </div>
      <div id="services" className="home-section-separator2"></div>
      <div className="home-services">
        <div className="home-heading-container">
          <h2 className="home-text09 Section-Heading">Services</h2>
          <span className="home-text10 Section-Text">
            What I can offer
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </div>
        <div className="home-cards-container">
          <div className="home-card">
            <div className="home-icon-container">
              <img alt="image" src="/mobile-200h.png" className="home-image3" />
            </div>
            <div className="home-content-container">
              <span className="home-heading1 Card-Heading">
                Mobile Applications
              </span>
              <span className="home-text11 Card-Text">
                <span>
                  Do you want to turn your requirements
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>into beautiful mobile apps?</span>
                <br></br>
                <span>Let me help!</span>
                <br></br>
              </span>
            </div>
          </div>
          <div className="home-card1">
            <div className="home-icon-container1">
              <img alt="image" src="/cloud-200h.png" className="home-image4" />
            </div>
            <div className="home-content-container1">
              <span className="home-heading2 Card-Heading">
                Cloud Migration
              </span>
              <span className="home-text18 Card-Text">
                <span>Do you want to use </span>
                <span>
                  cloud architecture
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>like AWS in your product?</span>
                <br></br>
                <span>Let me help!</span>
              </span>
            </div>
          </div>
          <div className="home-card2">
            <div className="home-icon-container2">
              <img alt="image" src="/desk-200h.png" className="home-image5" />
            </div>
            <div className="home-content-container2">
              <span className="home-heading3 Card-Heading">Freelancing</span>
              <span className="home-text25 Card-Text">
                <span>
                  Do you want someone extra to help out
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>building of your product?</span>
                <br></br>
                <span>Let me help!</span>
                <br></br>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="home-section-separator3"></div>
      <div className="home-technologies">
        <div className="home-heading-container1">
          <h1 className="home-text32 Section-Heading">Tech Stack</h1>
          <span className="home-text33 Section-Text">
            Technologies that I am working with
          </span>
        </div>
        <div className="home-logo-container">
          <img alt="image" src="/figma.svg" className="home-logo" />
          <img alt="image" src="/adobe-xd.svg" className="home-logo01" />
          <img alt="image" src="/swift.svg" className="home-logo02" />
          <img alt="image" src="/flutter.svg" className="home-logo03" />
          <img alt="image" src="/bloc.svg" className="home-logo04" />
        </div>
        <div className="home-logo-container1">
          <img alt="image" src="/golang.svg" className="home-logo05" />
          <img alt="image" src="/python.svg" className="home-logo06" />
          <img alt="image" src="/docker.svg" className="home-logo07" />
          <img alt="image" src="/aws.svg" className="home-logo08" />
        </div>
      </div>
      <div id="experience" className="home-section-separator4"></div>
      <div className="home-experience">
        <div className="home-max-content-width-container">
          <div className="home-heading-container2">
            <h1 className="home-text34 Section-Heading">
              Professional Experience
            </h1>
          </div>
          <div className="home-content-container3">
            <div className="home-about-1">
              <div className="home-container2">
                <svg viewBox="0 0 1024 1024" className="home-icon06">
                  <path d="M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z"></path>
                </svg>
                <span className="home-text35">
                  <span className="Card-Heading">Software Engineer</span>
                  <span className="Card-Text">:</span>
                  <br></br>
                  <span className="Card-Text">2021 - 2024</span>
                  <br></br>
                  <span className="Card-Text">Samsung - Remote/Poland</span>
                  <br></br>
                </span>
              </div>
              <div className="home-container3">
                <svg viewBox="0 0 1024 1024" className="home-icon08">
                  <path d="M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z"></path>
                </svg>
                <span className="home-text43">
                  <span className="Card-Heading">Freelancer</span>
                  <span className="Card-Text">:</span>
                  <br></br>
                  <span className="Card-Text">2019 - 2024</span>
                  <br></br>
                  <span className="Card-Text">
                    Remote - USA, UK, Norway, ...
                  </span>
                  <br className="Card-Text"></br>
                  <span className="Card-Text">
                    Main Technologies: Figma, Flutter, Go
                  </span>
                  <br></br>
                </span>
              </div>
              <div className="home-container4">
                <svg viewBox="0 0 1024 1024" className="home-icon10">
                  <path d="M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z"></path>
                </svg>
                <span className="home-text53">
                  <span className="Card-Heading">Software Engineer</span>
                  <span className="Card-Text">:</span>
                  <br></br>
                  <span className="Card-Text">2021</span>
                  <br></br>
                  <span className="Card-Text">Bank Pekao - Poland</span>
                  <br></br>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-section-separator5"></div>
      <div className="home-clients">
        <div className="home-heading-container3">
          <h1 className="home-text61 Section-Heading">Clients</h1>
          <span className="home-text62 Section-Text">
            Business partners all around the world
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </div>
        <div className="home-logo-container2">
          <img alt="image" src="/usa.svg" className="home-logo09" />
          <img alt="image" src="/south%20korea.svg" className="home-logo10" />
          <img alt="image" src="/uk.svg" className="home-logo11" />
          <img alt="image" src="/poland.svg" className="home-logo12" />
          <img alt="image" src="/norway.svg" className="home-logo13" />
        </div>
      </div>
      <div id="contact" className="home-section-separator6"></div>
      <div className="home-contact">
        <div className="home-container5">
          <div className="home-heading-container4">
            <h1 className="home-text63 Section-Heading">Contact me</h1>
            <span className="home-text64">
              <span className="Section-Text home-text65">
                You will be contacted by your email address.
              </span>
              <br className="Section-Text home-text66"></br>
              <span className="Section-Text home-text67">
                Your data will not be shared with anyone.
              </span>
              <br></br>
            </span>
          </div>
          <form
            action="https://formsubmit.co/emailjsbiencos@gmail.com"
            method="POST"
            enctype="application/x-www-form-urlencoded"
            className="home-form"
          >
             <fieldset>
            <input
              type="email"
              name="ClientMail"
              placeholder="Enter your email"
              className="home-textinput Section-Text input"
              pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              required="true"
            />
            </fieldset>
            {/* Todo */}
            {/* <input
              type="tel"
              name="ClientPhoneNumber"
              placeholder="Enter your phone (optional)"
              className="home-textinput1 Section-Text input"
            /> */}
            <fieldset>
            <textarea
              name="ClientProjectIdea"
              rows="5"
              placeholder="Enter your project idea"
              className="home-textarea Section-Text textarea"
              minLength="5"
              required="true"
            ></textarea>
            </fieldset>
            <input type="hidden" name="_next" value="https://www.biencos.com/thanks"></input>
            <button type="submit" className="home-button Anchor button">
              SEND
            </button>
          </form>
        </div>
        <div className="home-container6">
          <img alt="image" src="/phone_chat.svg" className="home-image6" />
        </div>
      </div>
      <div className="home-section-separator7"></div>
      <div className="home-footer">
        <div className="home-footer1">
          <div className="home-social-links">
            <a
              href="https://linkedin.com/in/sever-b-259052211"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link08"
            >
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon12">
                <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
              </svg>
            </a>
            <a
              href="https://github.com/biencos"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link09"
            >
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon14">
                <path d="M438.857 73.143c242.286 0 438.857 196.571 438.857 438.857 0 193.714-125.714 358.286-300 416.571-22.286 4-30.286-9.714-30.286-21.143 0-14.286 0.571-61.714 0.571-120.571 0-41.143-13.714-67.429-29.714-81.143 97.714-10.857 200.571-48 200.571-216.571 0-48-17.143-86.857-45.143-117.714 4.571-11.429 19.429-56-4.571-116.571-36.571-11.429-120.571 45.143-120.571 45.143-34.857-9.714-72.571-14.857-109.714-14.857s-74.857 5.143-109.714 14.857c0 0-84-56.571-120.571-45.143-24 60.571-9.143 105.143-4.571 116.571-28 30.857-45.143 69.714-45.143 117.714 0 168 102.286 205.714 200 216.571-12.571 11.429-24 30.857-28 58.857-25.143 11.429-89.143 30.857-127.429-36.571-24-41.714-67.429-45.143-67.429-45.143-42.857-0.571-2.857 26.857-2.857 26.857 28.571 13.143 48.571 64 48.571 64 25.714 78.286 148 52 148 52 0 36.571 0.571 70.857 0.571 81.714 0 11.429-8 25.143-30.286 21.143-174.286-58.286-300-222.857-300-416.571 0-242.286 196.571-438.857 438.857-438.857zM166.286 703.429c1.143-2.286-0.571-5.143-4-6.857-3.429-1.143-6.286-0.571-7.429 1.143-1.143 2.286 0.571 5.143 4 6.857 2.857 1.714 6.286 1.143 7.429-1.143zM184 722.857c2.286-1.714 1.714-5.714-1.143-9.143-2.857-2.857-6.857-4-9.143-1.714-2.286 1.714-1.714 5.714 1.143 9.143 2.857 2.857 6.857 4 9.143 1.714zM201.143 748.571c2.857-2.286 2.857-6.857 0-10.857-2.286-4-6.857-5.714-9.714-3.429-2.857 1.714-2.857 6.286 0 10.286s7.429 5.714 9.714 4zM225.143 772.571c2.286-2.286 1.143-7.429-2.286-10.857-4-4-9.143-4.571-11.429-1.714-2.857 2.286-1.714 7.429 2.286 10.857 4 4 9.143 4.571 11.429 1.714zM257.714 786.857c1.143-3.429-2.286-7.429-7.429-9.143-4.571-1.143-9.714 0.571-10.857 4s2.286 7.429 7.429 8.571c4.571 1.714 9.714 0 10.857-3.429zM293.714 789.714c0-4-4.571-6.857-9.714-6.286-5.143 0-9.143 2.857-9.143 6.286 0 4 4 6.857 9.714 6.286 5.143 0 9.143-2.857 9.143-6.286zM326.857 784c-0.571-3.429-5.143-5.714-10.286-5.143-5.143 1.143-8.571 4.571-8 8.571 0.571 3.429 5.143 5.714 10.286 4.571s8.571-4.571 8-8z"></path>
              </svg>
            </a>
          </div>
          <div className="home-copyright-container">
            <svg viewBox="0 0 1024 1024" className="home-icon16">
              <path d="M512 854q140 0 241-101t101-241-101-241-241-101-241 101-101 241 101 241 241 101zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125zM506 390q-80 0-80 116v12q0 116 80 116 30 0 50-17t20-43h76q0 50-44 88-42 36-102 36-80 0-122-48t-42-132v-12q0-82 40-128 48-54 124-54 66 0 104 38 42 42 42 98h-76q0-14-6-26-10-20-14-24-20-20-50-20z"></path>
            </svg>
            <span className="home-link10 Anchor">
              Copyrighted by biencos, 2024
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
