import React from 'react'

import { Helmet } from 'react-helmet'

import './thanks.css'

const Thanks = (props) => {
  return (
    <div className="thanks-container">
      <Helmet>
        <title>Thanks</title>
        <meta property="og:title" content="Thanks" />
      </Helmet>
      <div className="thanks-thanks">
        <div className="thanks-max-content-width-container">
          <div className="thanks-card">
            <div className="thanks-card-content">
              <h1 className="thanks-text">Thank You</h1>
              <div className="thanks-icon-container">
                <img
                  alt="image"
                  src="/email.png"
                  className="thanks-image"
                />
              </div>
              <div className="thanks-content-container">
                <span className="thanks-heading">Your idea was sent</span>
                <span className="thanks-text1">
                  <span className="Card-Text">
                    You will be contacted
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="Card-Text">shortly via your email.</span>
                  <br className="Card-Text"></br>
                  <span className="Card-Text">
                    Be sure to check out your inbox.
                  </span>
                  <br></br>
                </span>
              </div>
            </div>
          </div>
          <a
            href="/"
            rel="noreferrer noopener"
            className="thanks-cta-btn button Anchor"
          >
            <span className="thanks-text7">Home</span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Thanks
